import { Page } from '~/cms/front/core/pages/Page'
import component from './ProjectsPage.vue'

class ProjectsPage extends Page {
	static alias = 'eu-projects'

	async init () {
		await super.init(...arguments)
	}

	component = component
}

export { ProjectsPage }
