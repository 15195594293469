<template>
	<div class="section-wrapper">
		<base-container>
			<div class="project-logos">
				<cms-image
					v-for="(logo, id) in langs.logos"
					:key="id"
					lazy
					:src="logo.logo"
					:base="{width: 115}"
					:md="{width: 230}"
				/>
			</div>
			<base-heading heading="project_page.main_title"  subheading="project_page.main_subtitle"/>
				<div class="grid-wrapper">
					<div class="content-wrapper">
						<div v-html="langs.text_content"></div>
						<base-link :url="`mailto:${langs.email_link}`">{{langs.email_link}}</base-link>
					</div>
					<cms-image
						value="project_page.hero_image"
						class="hero-img"
						lazy
						:base="{width: 318}"
						:md="{width: 690, height: 200}"
						:lg="{width: 690, height: 460}"
						:xl="{width: 690, height: 460}"
					/>
				</div>
		</base-container>
	</div>
</template>
<script>
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		BaseHeading
	},
	mixins: [
		pageMixin
	],
	props: {
		prefix: String
	},
	computed: {
		langs () {
			return this.$app.page.value.project_page
		}
	}

}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 8rem 0 4rem 0;
	background: $white;
	@include media-breakpoint-up(xl) {
		padding: 14rem 0 4rem 0;
	}
	.project-logos {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
		margin-bottom: 2rem;
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 3rem;
		}
	}
	.hero-img ::v-deep .base-image {
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: 100%;
			width: auto;
		}
	}
	.grid-wrapper {
		display: grid;
		gap: 1rem;
		@include media-breakpoint-up(lg) {
			gap: 2rem;
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
</style>
